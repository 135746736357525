<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />

    <div class="row mb-3">
      <div class="col-sm-12 col-md-9">
        <h1 class="m-0">
          {{ form.name }}
        </h1>

        <template v-if="form.trigger">
          <span class="badge badge-pill bg-secondary text-uppercase">Trigger: {{ form.trigger | rmDashes }}</span>
        </template>

        <p>
          <router-link :to="{name: 'message_templates.index'}">Message Templates</router-link> /
          <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}
        </p>

      </div><!--Col-->
      <div class="col-sm-12 col-md-3 text-end">
        <div class="btn-group">

          <template v-if="form.templates['en']">
          <a 
          :href="'/preview?simulate=true&content='+form.templates['en']" 
          class="btn btn-outline-primary"
          target="_blank"
          :disabled="processing"
          >
          <i class="fa fa-eye"></i>
          Preview
          </a>
          </template>

          <button
            type="button"
            class="btn btn-secondary dropdown-toggle radius-right-0"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Tools
          </button>
          <div class="dropdown-menu">
            <a
              v-if="form.deleted_at || form.archived_at"
              class="dropdown-item"
              @click="restoreRecord(form)"
            >Restore</a>
            <a
              v-if="!form.deleted_at && !form.archived_at"
              class="dropdown-item"
              @click="trashRecord(form)"
            >Trash</a>
            <a
              v-if="!form.archived_at && !form.deleted_at"
              class="dropdown-item"
              @click="archiveRecord(form)"
            >Archive</a>
          </div>
          <button
            v-if="!form.deleted_at"
            type="submit"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>
          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>
        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            
            <p class="card-title">Edit {{ $route.meta.title }}</p>

            <form @submit.prevent="postForm">

              <div class="form-group">
                <label for="name">Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="trigger">Trigger</label> <br>
                <select
                  id="trigger"
                  v-model="form.trigger"
                  class="form-control"
                  :disabled="processing"
                >
                <template v-if="options && options.auto_message_triggers">
                  <option
                    v-for="trigger in options.auto_message_triggers"
                    :value="trigger.value"
                    >
                      {{ trigger.label }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="recipients">Additional Recipients</label>
                <input id="recipients" v-model="form.recipients" type="text" class="form-control">
                <p class="form-text text-muted">Comma-separated list of phones and emails</p>
              </div>

              <div class="form-group">
                <label for="max_messages">Maximum Messages</label>
                <input
                  id="max_messages"
                  v-model="form.max"
                  type="number"
                  class="form-control"
                  :disabled="processing"
                >
                <small class="form-text text-muted">Defaults to 0 for unlimited. Set to 1 for a single message per contact.</small>
              </div>

              <div class="form-check">
                <input
                  id="simulate"
                  v-model="form.simulate"
                  type="checkbox"
                  class="form-check-input"
                  @change="postForm()"
                  :disabled="processing"
                >
                <label
                  class="form-check-label"
                  for="simulate"
                >Simulate Mode</label>
              </div>

              <template v-if="form.simulate">
                  <p class="form-text text-muted small">
                    Any messages will be sent in Simulate Mode (no actual messages will be sent).
                  </p>
              </template>

                <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->


        <template v-if="form && form.uuid">
          <audit-logs
            :form="form"
            class="mt-3"
          />
        </template>
      </div><!--Col-->


      <div class="col-sm-12 col-md-6">

        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-sm-12 col-md-4">
                <p class="card-title"><i class="fa fa-language"></i> Message Templates</p>
              </div><!--Col-->
              <div class="col-sm-12 col-md-4 text-end">
                <button
                      type="button"
                      class="btn btn-outline-primary btn-sm"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      id="filesButton"
                      :disabled="processing"
                    >
                      <i class="fa fa-file-pdf"></i> Insert Files
                    </button>
                    <div class="dropdown-menu" aria-labelledby="filesButton" style="max-height:400px; overflow-y:auto">

                      <div class="p-2">
                        <p class="card-title">{{ files.length }} Files</p>
                        <label class="sr-only" for="search_files">Search</label>
                        <input id="search_files" class="form-control" type="text" placeholder="Search Files" v-model="search_files">
                      </div>

                      <template v-for="file in files">
                          <a
                            class="dropdown-item"
                            @click="insertFile(file.merge_tag)"
                            :hidden="!showFile(file)"
                          >{{ file.name }} </a>
                      </template>

                      <template v-if="files && !files.length && !processing">
                        <a class="dropdown-item" href="/files">Upload Files</a>
                      </template>

                    </div>
              </div>
              <div class="col-sm-12 col-md-4 text-end">
                <button
                    type="button"
                    class="btn btn-outline-primary btn-sm"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    id="mergeTagButton"
                    :disabled="processing"
                >
                  <i class="fa fa-code"></i> Merge Tags
                </button>
                <div class="dropdown-menu" aria-labelledby="mergeTagButton" style="max-height:400px;overflow-y:auto">
                  <div class="p-2">
                    <p class="card-title">{{ merge_tags.length }} Merge Tags</p>
                    <label class="sr-only" for="search_merge">Search</label>
                    <input id="search_merge" class="form-control" type="text" placeholder="Search Merge Tags" v-model="search_merge">
                      </div>
                  <template v-for="merge_tag in merge_tags">
                    <template v-if="showMergeTag(merge_tag)">
                    <a
                        class="dropdown-item"
                        @click="copyToClipboard(merge_tag.value)"
                    >{{ merge_tag.value }}</a>
                    </template>
                  </template>
                </div>
              </div><!--Col-->
            </div>

            <div class="form-group">
                <label for="subject">Subject</label>
                <input
                  id="subject"
                  v-model="form.subject"
                  type="text"
                  class="form-control"
                  @keypress.enter="postForm"
                  >
              </div>

              <template v-if="!processing && !languages.length">
                <div class="alert alert-danger">Choose enabled languages in
                  <a href="/settings">General Settings</a>
                </div>
              </template>


              <template v-for="language in languages">

                <div class="form-group mt-3" :hidden="language.key != 'en' && show_languages === false">
                  <label
                    for="language_templates"
                    class="text-capitalize"
                  >
                  {{ language.label }} Template</label>
                  <textarea
                    id="language_templates"
                    ref="templatesText"
                    v-model="templates[language.key]"
                    class="form-control"
                    :class="setValidateClass(templates[language.key])"
                    rows="8"
                  />

                  <template v-if="templates[language.key] && templates[language.key].length">
                    <div class="mt-2">
                      <small class="small text-muted float-end">Characters: <span class="badge badge-pill" :class="setCharacterClass(templates[language.key])">{{ templates[language.key].length }}</span></small>
                    </div>
                  </template>

                  <template v-if="language.key !== 'en' && templates['en']">
                    <button 
                      type="button" 
                      class="btn btn-outline-success btn-sm text-capitalize"
                      @click="autoTranslate(language)"
                      :disabled="processing"
                      >
                      <i class="fa fa-language"></i> Auto Translate {{ language.label }}
                    </button>
                    <template v-if="templates[language.key]">
                    <a 
                      :href="'/preview?simulate=true&content='+templates[language.key]" 
                      class="btn btn-outline-primary btn-sm"
                      target="_blank"
                      :disabled="processing"
                      >
                      Preview
                    </a>
                    </template>
                  </template>


                </div>

                <template v-if="language.key === 'en'">
                  <button type="button" class="btn btn-outline-primary" @click="show_languages = !show_languages">
                <i class="fa fa-language"></i> 
                <template v-if="show_languages">
                  Hide
                </template>
                <template v-else>
                  Show
                </template>
                Languages
              </button>

              <template v-if="show_languages">
                <button type="button" class="btn btn-outline-success float-end" @click="autoTranslateAll" :disabled="processing">
                  <i class="fa fa-language"></i> Auto Translate All
                </button>
                </template>
              
              </template>

              </template>

              <div class="form-group mt-3">
                <label for="file_url">Attach File</label>
                <select
                  id="file_url"
                  v-model="form.file_url"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-if="files && files.length > 0">
                    <template v-for="file in files">
                      <option
                        :value="file.url"
                      >
                        {{ file.name }}
                      </option>
                    </template>
                  </template>
                </select>

                <template v-if="form.file_url">
                    <a 
                    :href="'/files?url='+form.file_url" 
                    target="_blank"
                    class="text-muted small"
                    >
                      <i class="fa fa-link"></i>
                      View File
                    </a>
                  </template>
                  <template v-else>
                    <p class="small text-muted">
                      <a
                        href="/files"
                        target="_blank"
                      >Manage files</a>
                    </p>
                  </template>
              </div>

            </div><!--Body-->
          </div><!--Card-->

          <div class="card my-3">
            <div class="card-body">
              <p class="card-title"><i class="fa fa-clock"></i> Scheduling</p>
              <p class="card-text">
                Configure when this message is sent.
              </p>

              <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="delay">Delay</label>
                <input
                  id="delay"
                  v-model="form.delay"
                  type="number"
                  class="form-control"
                  min="0"
                  step="1"
                >
                <small class="form-text text-muted">In minutes. Defaults to 5</small>
              </div>

              <div class="form-check">
                <input
                  id="delay_business"
                  v-model="form.delay_business"
                  type="checkbox"
                  class="form-check-input"
                  @change="postForm()"
                  :disabled="processing"
                >
                <label
                  class="form-check-label"
                  for="delay_business"
                >Delay to Business Hours</label>
              </div>

              <template v-if="form.delay_business">
                <template v-if="settings && settings.business_from_time && settings.business_to_time">
                  <p class="form-text text-muted small m-0">
                    Business Hours {{ settings.business_from_time | time }} - {{ settings.business_to_time | time }}
                  </p>
                  <p class="form-text text-muted small m-0">
                    Timezone {{ settings.timezone }}
                  </p>
                  <a href="/settings?search=business" target="_blank" class="text-decoration-none m-0 text-muted small">Edit Business Hours</a>
                </template>
              </template>

              <template v-if="form.uuid && !form.delay_business">
                <p class="text-danger small">
                  <i class="fa fa-exclamation-triangle"></i>
                  This message can be sent at any time.
                </p>
              </template>

              <button type="submit" class="btn btn-success" hidden>Save</button>

              </form>

            </div>
          </div>


          <div class="card my-3">
            <div class="card-body">
              <p class="card-title"><i class="fa fa-filter"></i> Filters</p>
              <p class="card-text">Configure filters to restrict when this message is sent.</p>

              <form @submit.prevent="postForm()">

                <div class="form-group">
                <label for="value">Enabled Trigger</label> <br>
                <select
                    id="value"
                    v-model="form.value"
                    class="form-control"
                    @change="postForm"
                    :disabled="processing"
                >
                  <option value=""></option>
                  <template v-if="form.trigger == 'client_group'">
                    <template v-for="group in groups">  
                      <option :value="group">{{ group }}</option>
                    </template>
                  </template>
                  <template v-else>
                    <template v-for="status in statuses">
                      <option
                        v-for="status in statuses"
                        :value="status.name"
                      >
                        {{ status.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <template v-if="!processing && !statuses.length">
                <div class="alert alert-info">
                  <router-link
                    class="alert-link"
                    :to="{ 'name': 'statuses.index' }"
                    :disabled="processing"
                >Create statuses here.</router-link>
                </div>
              </template>

              <div class="form-check">
                <input
                  id="ignore_disabled"
                  v-model="form.ignore_disabled"
                  type="checkbox"
                  class="form-check-input"
                  @change="postForm()"
                  :disabled="processing"
                >
                <label
                  class="form-check-label"
                  for="ignore_disabled"
                >Override Statuses that Disable Messages</label>
              </div>

              <template v-if="form.trigger === 'client_created' || form.trigger === 'event_created' || form.trigger === 'case_created' || form.trigger == 'event_status'">
                 <div class="form-check">
                  <input
                    id="future_events"
                    v-model="form.future_events"
                    type="checkbox"
                    class="form-check-input"
                    :disabled="processing"
                  >
                  <label
                    class="form-check-label"
                    for="future_events"
                  >Require Future Date</label>
                </div>


                <div class="form-group">
                  <label for="future_events_from">Future Date From</label>
                  <input id="future_events_from" v-model="form.settings['future_events_from']" type="number" class="form-control" :disabled="processing">
                  <span class="form-text text-muted small">In days. Leave blank or 0 for same day.</span>
                </div>

                <div class="form-group">
                  <label for="future_events_to">Future Date To</label>
                  <input id="future_events_to" v-model="form.settings['future_events_to']" type="number" class="form-control" :disabled="processing">
                  <span class="form-text text-muted small">In days. Leave blank or 0 for infinity.</span>
                </div>


                </template>


              <div class="form-group">
                <label for="client_status">Client Status</label>
                <select id="client_status" v-model="form.settings['client_status']" class="form-control" @change="postForm" :disabled="processing">
                  <option value="" />
                  <template v-for="status in client_statuses">
                    <option :value="status.name">{{ status.name }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="client_group">Client Group</label>
                <select id="client_group" v-model="form.settings['client_group']" class="form-control" @change="postForm" :disabled="processing">
                  <option value="" />
                  <template v-for="group in groups">
                    <option :value="group">{{ group }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="client_type">Client Type</label>
                <input id="client_type" v-model="form.settings['client_type']" type="text" class="form-control" @keypress.enter="postForm" :disabled="processing">
              </div>

              <template v-if="form.trigger == 'monthly_payment_balance'">

              <div class="form-group">
                <label for="payment_statuses">Payment Statuses</label>
                <input id="payment_statuses" v-model="form.settings['payment_statuses']" type="text" class="form-control" @keypress.enter="postForm" :disabled="processing">
                <p class="form-text text-muted">Comma separated list of statuses</p>
              </div>

              <div class="form-group">
                <label for="payment_date_field">Payment Date Field</label>
                <input id="payment_date_field" v-model="form.settings['payment_date_field']" type="text" class="form-control" @keypress.enter="postForm" :disabled="processing">
              </div>

              <div class="form-group">
                <label for="payment_date_difference">Payment Date Difference</label>
                <input id="payment_date_difference" v-model="form.settings['payment_date_difference']" type="number" class="form-control" @keypress.enter="postForm" :disabled="processing">
              </div>

              </template>

              </form>

            </div><!--Body-->
          </div><!--Card-->


      </div><!--Col-->

      <div class="col-sm-12 col-md-3">

        <template v-if="total_messages >= 0">
          <p class="card-title"><a :href="'/messages?type=auto_message:'+form.name">{{ messages.length }} of {{ total_messages.toLocaleString() }} Messages</a></p>
        </template>

        <template v-for="message in messages">

          <message
            :message="message"
          />

        </template>

      </div><!--Col-->

    </div><!--Row-->

  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

    mixins: [ queries ],

    data() {
        return {
            processing: false,
            form: {},
            queries: {
                limit: 1000,
                slim: 'name,uuid,trigger',
                sort: 'name',
                sortDirection: 'asc'
            },
            templates: {
                'en': ''
            },
            records: [],
            files: [],
            search_files: '',
            current_language: {label: 'English', key: 'en'},
            groups: [],
            total: 0,
            messages: [],
            total_messages: 0,
            merge_tags: [],
            search_merge: '',
            statuses: [],
            client_statuses: [],
            show_languages: false
        }
    },

    created() {
        this.resetForm();
        this.setURLParams();
        this.getRecord();
        this.getOptions();
        this.getLanguages();
        this.getMergeTags();
        this.getFiles();
        this.getGroups();
    },
    
    methods: {

    resetForm() {
        this.form = {
            templates: {
                'en': ''
            },
            settings: {},
            name: null,
            subject: null,
            trigger: null,
            value: null,
            delay: null,
            delay_business: null,
            future_events: null,
            file_url: null,
            ignore_disabled: null,
            recipients: null,
            simulate: 0,
            max: 1
        };

        this.templates = {
            'en': ''
        }

        this.messages = [];

        this.total_messages = 0;
    },

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        })
    },

    getRecord() {

        this.processing = true;

        this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
        .then(response => {
            if(response && response.data) {
                
                this.form = response.data;

                if(!this.form.settings || this.form.settings.length === 0) {
                    this.form.settings = {
                      'client_status': null,
                      'client_group': null,
                      'client_type': null
                    };
                }
                
                if(!response.data.templates) {
                    this.templates = {
                        'en': ''
                    }
                }

                this.setTitle();

                if(response.data.templates) {
                    this.templates = response.data.templates;
                }

                this.totalMessages();

                this.getStatuses();
                this.getClientStatuses();
                this.processing = false;
            }
        })
    },

      setTitle() {
        document.title = this.form.name + ' | ' + this.$route.meta.title;
      },


    postForm() {

        this.setForm();

        this.processing = true;
        this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
            .then(response => {
              if(response && response.data) {

                  this.processing = false;

                  if(response.data.updated_at) {
                    this.$eventBus.$emit('show-alert', {
                      text: this.$route.meta.title + ' updated'
                    });
                    this.form.updated_at = response.data.updated_at;
                  }
              }
            })
      },

    setForm() {
        this.form.templates = this.templates;

        if(!this.form.settings || this.form.settings == null) {
          this.form.settings = {};
        }
    },

    getLanguages() {
      this.$http.get('/languages')
      .then(response => {
        if(response && response.data) {
          this.languages = response.data;
        }
      })
    },

    insertFile(merge_tag) {

      if(!this.form.templates['en']) {
        this.form.templates['en'] = '';
      }

      if(this.form.templates['en'].includes(merge_tag)) {
        return;
      }

      this.form.templates['en'] += ' {'+merge_tag+'}';
    },

    showFile(file) {
      if(!file) {
        return;
      }

      if(!this.search_files) {
        return true;
      }

      if(file.name.toLowerCase().includes(this.search_files.toLowerCase())) {
        return true;
      }

      return false;
    },

      showMergeTag(merge_tag) {
        if(!this.search_merge) {
          return true;
        }

        return merge_tag.value.toLowerCase().includes(this.search_merge.toLowerCase());
      },

    getFiles() {
        this.$http.get('/files?slim=true&fields=name,url,merge_tag&sort=created_at&sortDirection=desc&limit=100')
        .then(response => {
            if(response && response.data) {
                this.files = response.data;
            }
        })
    },

    getStatuses() {
      this.$http.get('/statuses?limit=100&sort=name&sortDirection=desc')
      .then(response => {
        if(response && response.data) {
          this.statuses = response.data;
        }
      })
    },

    getClientStatuses() {
      this.$http.get('/statuses?limit=100&sort=name&sortDirection=desc&type=client')
      .then(response => {
        if(response && response.data) {
          this.client_statuses = response.data;
        }
      })
    },

    getMessages() {

      if(!this.total_messages || this.total_messages === 0) {
        return;
      }

      this.$http.get('/messages?sort=scheduled_at&sortDirection=desc&slim=true&type=auto_message:'+this.form.name)
      .then(response => {
        if(response && response.data) {

          if(response.data.data.length > 0) {
            this.messages = response.data.data; 
          }
        }
      })
    },


    totalMessages() {

      if(!this.form.name) {
        return;
      }

      this.$http.get('/messages/total?type=auto_message:'+this.form.name)
      .then(response => {
        if(response && response.status === 200) {
          this.total_messages = response.data;

          this.getMessages();
        }
      })
    },

    autoTranslateAll() {

    if(!this.languages || this.languages.length === 0) {
      return;
    }

    this.languages.forEach(language => {

      if(language.key === 'en') {
        return;
      }

      this.autoTranslate(language);
    });
    },

    autoTranslate(language) {

      this.processing = true;

      const content = this.form.templates['en'];

      if(!content) {
        return;
      }

      var translator = {
        source_language: 'en',
        target_language: language.key,
        content: content
      }

      this.$http.post('/translator', translator)
      .then(response => {
        if(response && response.data) {

          if(response.data.content) {
            this.form.templates[language.key] = response.data.content;
            this.templates[language.key] = response.data.content;
            this.$eventBus.$emit('show-alert', {
              text: 'Added translation to '+language.label
            });

            this.postForm();
          }

          this.processing = false;
        }
      })
    },

      setValidateClass(value) {

        if(!value) {
          return;
        }

        var tags = this.extractMergeTags(value);

        if(!tags) {
          return;
        }

        var values = this.merge_tags.map(item => item['value']);

        values = values.map(item => item.replace(/\[|\]/g, ''));

        function hasInvalidTags(tags, values) {
          return tags.some(item => !values.includes(item));
        }

        if(hasInvalidTags(tags, values)) {
          return 'is-invalid';
        }

      },

      extractMergeTags(value) {
        const mergeTagRegex = /\[([^\]]+)\]/g;
        const mergeTags = [];
        let match;

        while ((match = mergeTagRegex.exec(value)) !== null) {
          mergeTags.push(match[1].replace(/%.*?%/g, '').trim());
        }

        return mergeTags;
      },

      setCharacterClass(value) {

      if(!value || !value.length) {
        return 'badge-secondary';
      }

      if(value.length > 1500) {
        return 'badge-danger';
      }

      if(value.length > 320) {
        return 'badge-warning';
      }

      if(value.length > 10 && value.length < 320) {
        return 'badge-success';
      }

      return 'badge-secondary';
      },

    }
}
</script>