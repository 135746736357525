<template>
  <div>

    <page-tabs page="portals" />

    <div class="row">
      <div class="col-sm-12 col-md-3 d-print-none">
        <div class="card">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-6">
                <p class="card-title">
                  Create {{ $route.meta.title }}s
                </p>
              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-end">

                <template v-if="qr_code">
                  <template v-if="form.content">
                    <a :href="form.content" class="btn btn-outline-primary btn-sm" target="_blank">
                      <i class="fa fa-link"></i> Open Link
                    </a>
                  </template>
                  <button type="button" class="btn btn-danger btn-sm" @click="resetQR()" :disabled="processing">Close</button>
                </template>

              </div>

            </div>

            <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="content">QR Code Link</label>
                <input
                    id="content"
                    v-model="form.content"
                    class="form-control"
                    required
                >
              </div>

              <div class="form-group">
                <label for="logo">Logo URL</label>
                <input id="logo" class="form-control" type="text" v-model="form.logo">
              </div>

              <template v-if="form.format == 'pdf'">

              <div class="form-group">
                <label for="title">Title</label>
                <input id="title" class="form-control" type="text" v-model="form.title">
              </div>

              <div class="form-group">
                <label for="description">Description</label>
                <textarea id="description" class="form-control" v-model="form.description" rows="10"></textarea>
              </div>

              <div class="form-group">
                <label for="amount">Payment Amount</label>
                <input id="amount" type="number" class="form-control" step="0.01" v-model="form.amount">
              </div>

                <div class="form-group">
                  <label for="language">Language</label>
                  <select
                      id="language"
                      v-model="form.language"
                      class="form-control"
                      :disabled="processing"
                  >
                    <option value="">
                      Choose Language
                    </option>
                    <option value="en">English</option>

                    <template v-for="language in languages">

                      <option
                          :value="language.key"
                          :hidden="language.key == 'en'"
                      >
                        {{ language.label }}
                      </option>

                    </template>

                  </select>
                </div>

              </template>

              <div class="form-group">
                <label class="form-label">Format</label>
                <div>
                  <div class="form-check form-check-inline">
                    <input
                        id="pdf"
                        v-model="form.format"
                        type="radio"
                        name="format"
                        value="pdf"
                        class="form-check-input"
                        @change="postForm()"
                        :disabled="processing"
                    >
                    <label
                        class="form-check-label"
                        for="pdf"
                    >PDF</label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                        id="svg"
                        v-model="form.format"
                        type="radio"
                        name="format"
                        value="svg"
                        class="form-check-input"
                        @change="postForm()"
                        :disabled="processing"
                    >
                    <label
                        class="form-check-label"
                        for="svg"
                    >SVG</label>
                  </div>

                  <template v-if="form.logo && form.logo.indexOf('.svg') == -1">
                    <div class="form-check form-check-inline">
                      <input
                          id="png"
                          v-model="form.format"
                          type="radio"
                          name="format"
                          value="png"
                          class="form-check-input"
                          @change="postForm()"
                          :disabled="processing"
                      >
                      <label
                          class="form-check-label"
                          for="png"
                      >PNG</label>
                    </div>
                  </template>
                </div>
              </div>

              <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
              >
                Generate
              </button>

            </form>


          </div><!--Body-->
        </div><!--Card-->

        <template v-if="form.fields && form.fields.length">
        <div class="card my-3">
          <div class="card-body">
            <p class="card-title">
              Auto Populate QR Code
            </p>

            <template v-if="form.fields">
              <template v-for="field in form.fields">
                <div class="form-group">
                  <label :for="'parameter_'+field">{{ field }}</label>
                  <input :id="'parameter_'+field" class="form-control" type="text" v-model="parameters[field]" @change="updateParameters()">
                </div>
              </template>
            </template>
            
          </div>
          </div>
        </template>

      </div><!--Col-->

      <div class="col-sm-12 col-md-9">

        <template v-if="!qr_code">

                <p class="card-title">{{ records.length }} of {{ total }} QR Codes</p>

                <div class="row my-3">

                <template v-for="record in records">
                  <div class="col-sm-12 col-md-4 mb-3">
                    <div class="card">
                      <div class="card-body">

                      <p class="card-text h5">
                        {{ record.title }}
                      </p>

                      <template v-if="record.description">
                        <p>{{ record.description }}</p>                      
                      </template>

                      <p>{{ record.content }}</p>

                      <div class="btn-group">
                        <button type="button" class="btn btn-success" @click="generateQR(record)" :disabled="processing"><i class="fa fa-qrcode"></i> Generate</button>
                        <button type="button" class="btn btn-danger" @click="deleteRecord(record)" :disabled="processing"><i class="fa fa-trash"></i> Delete</button>
                      </div>

                      </div><!--Body-->
                    </div><!--Card-->

                  </div><!--Col-->
                </template>

                </div><!--Row-->

         
          <template v-if="samples && samples.length">
            <div class="card">
              <div class="card-body">
                <p class="card-title">
                  Sample {{ $route.meta.title }}s
                </p>


              <div class="form-group">
                <label for="portal">Portal</label>
                <select
                    id="portal"
                    v-model="form.portal"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="">
                    Choose Portal
                  </option>
                  <template v-for="portal in portals">
                    <option
                        :value="portal.uuid"
                    >
                      {{ portal.name }}
                    </option>
                  </template>
                </select>
              </div>

              <template v-if="currentPortal">
                <a :href="'/portals/'+currentPortal.uuid" class="btn btn-outline-primary btn-sm mb-3" target="_blank">
                  <i class="fa fa-edit"></i> Edit Portal
                </a>
              </template>

                <div class="row">
                  <template v-for="sample in samples">
                    <div class="col-sm-12 col-md-4 mb-3">
                      <div class="card">
                        <div class="card-body">
                          <p class="card-text h5">
                           {{ sample.title }}
                          </p>

                          <p>{{ sample.description }}</p>

                          <form @submit.prevent="importSample(sample)">

                          <template v-if="sample.action == 'link'">
                            <template v-if="currentPortalLink">
                              <p><a :href="currentPortalLink" class="text-decoration-none" target="_blank">{{ currentPortalLink }}</a></p>  
                            </template>

                          </template>

                          <template v-if="sample.action == 'checkin'">
                            <template v-if="currentPortalLink">
                              <p><a :href="currentPortalLink+'/checkin'" class="text-decoration-none" target="_blank">{{ currentPortalLink+'/checkin' }}</a></p>  
                            </template>
                          </template>

                          <template v-if="sample.action == 'enroll'">
                            <template v-if="currentPortalLink">
                              <p><a :href="currentPortalLink+'/enroll'" class="text-decoration-none" target="_blank">{{ currentPortalLink+'/enroll' }}</a></p>  
                            </template>
                          </template>

                          <template v-if="sample.action == 'file' || sample.action == 'esign'">

                            <div class="form-group">
                              <label :for="'sample_file_'+sample.title">File</label>
                              <select
                                  :id="'sample_file_'+sample.title"
                                  :ref="'sample_file_'+sample.title"
                                  v-model="form.file_url"
                                  class="form-control"
                                  :disabled="processing"
                                  required
                              >
                                <option value="">
                                  Choose File
                                </option>
                                <template v-for="file in files">
                                  <option
                                      :value="file.url"
                                  >
                                    {{ file.name }}
                                  </option>
                                </template>
                              </select>
                            </div>


                            <template v-if="currentPortalLink">
                              <p><a :href="currentPortalLink+'/library'" class="text-decoration-none" target="_blank">{{ currentPortalLink+'/library' }}</a></p>  
                            </template>

                          </template>

                          <template v-if="sample.action == 'payment'">

                            <div class="form-group">
                              <label :for="'sample_amount_'+sample.title">Amount</label>
                              <input :id="'sample_amount_'+sample.title" type="number" class="form-control" step="0.01" v-model="form.amount">
                            </div>

                            <template v-if="currentPortalLink">
                              <p><a :href="currentPortalLink" class="text-decoration-none" target="_blank">{{ currentPortalLink }}</a></p>  
                            </template>
                            
                          </template>

                          <template v-if="sample.action == 'call'">
                            <div class="form-group">
                              <label :for="'sample_phone_'+sample.title">Phone Number</label>
                              <input :id="'sample_phone_'+sample.title" type="tel" class="form-control" v-model="form.phone" required>
                            </div>
                          </template>

                          <template v-if="sample.action == 'contact'">
                            
                            <div class="form-group">
                              <label for="vcard_name">vCard Name *</label>
                              <input id="vcard_name" type="text" class="form-control" v-model="form.vcard_name" required>
                            </div>

                            <div class="form-group">
                              <label for="vcard_phone">vCard Phone</label>
                              <input id="vcard_phone" type="tel" class="form-control" v-model="form.vcard_phone">
                            </div>

                            <div class="form-group">
                              <label for="vcard_email">vCard Email</label>
                              <input id="vcard_email" type="email" class="form-control" v-model="form.vcard_email">
                            </div>
                          
                          </template>

                          <template v-if="sample.action == 'wifi'">
                            
                            <div class="form-group">
                              <label for="wifi_ssid">SSID</label>
                              <input id="wifi_ssid" type="text" class="form-control" v-model="form.wifi_ssid" required>
                            </div>

                            <div class="form-group">
                              <label for="wifi_password">Password</label>
                              <input id="wifi_password" type="text" class="form-control" v-model="form.wifi_password" required>
                            </div>

                            <div class="form-group">
                              <label for="wifi_security">Security</label>
                              <select
                                  id="wifi_security"
                                  v-model="form.wifi_security"
                                  class="form-control"
                                  required
                              >
                                <option value="">
                                  Choose Security
                                </option>
                                <option value="WPA">WPA</option>
                                <option value="WEP">WEP</option>
                                <option value="WPA2">WPA2</option>
                                <option value="WPA3">WPA3</option>
                              </select>
                            </div>
                            
                            
                          </template>

                          <button type="submit" class="btn btn-success" :disabled="processing">Generate</button>

                          </form>

                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>



        </template>


        <template v-if="qr_code && form.format != 'pdf'">
          <div class="btn-group d-print-none">
          <button type="button" class="btn btn-primary" @click="downloadFile()" :disabled="processing || downloading"><i class="fa file-arrow-down"></i> Download {{ form.format }}</button>
          </div>
        </template>



        <template v-if="qr_code && form.format == 'svg'">
          <img
              :src="'data:image/svg+xml;base64,'+qr_code"
              width="100%"
              height="100%"
              :alt="form.title || 'QR Code'"
          >
        </template>

        <template v-if="qr_code && form.format == 'png'">
          <img
              :src="'data:image/png;base64,'+qr_code"
              width="100%"
              height="100%"
              :alt="form.title || 'QR Code'"
          >

          <template v-if="form.content">
            {{ form.content }}
          </template>

        </template>

        <template v-if="qr_code && form.format == 'pdf'">
          <iframe :src="qr_code" width="100%" height="960" border="0px" frameborder="0px" :title="form.title || 'QR Code'"></iframe>
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      downloading: false,
      errors: null,
      form: {},
      qr_code: null,
      total: 0,
      records: [],
      samples: [],
      portals: [],
      files: [],
      parameters: {}
    }
  },

  computed: {

    currentPortal: function() {
        if(!this.form.portal) {
          return;
        }

        if(!this.portals || !this.portals.length) {
          return;
        }

        return this.portals.find(portal => portal.uuid === this.form.portal);
    },

    currentPortalLink: function() {
      if(this.currentPortal) {
        return 'https://'+this.currentPortal.link+'.'+this.currentPortal.domain;
      }
    }

  },

  mounted() {
    var self = this;

    this.$eventBus.$on('errors', function (errors) {
      self.errors = errors.data || errors;
      self.processing = false;
    });

  },

  created() {
    this.resetForm();
    this.setURLParams();

    this.setTitle();

    this.getPortals();

    if(this.settings['icon'] && !this.form.logo) {
      this.form.logo = this.settings['icon'];
    }

    if(this.settings['logo'] && !this.form.logo) {
      this.form.logo = this.settings['logo'];
    }

    if(this.form.content) {
      this.postForm();
    }

    this.getSamples();
    this.getRecords();
    this.getFiles();
  },

  methods: {

    setURLParams() {

      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {
        self.form[key] = value;
      });

      if(self.form.fields) {
        self.form.fields = self.form.fields.split(',');
      }
    },

    resetForm() {
      this.form = {
        portal: null,
        content: null,
        logo: null,
        title: null,
        description: null,
        amount: null,
        language: 'en',
        format: 'pdf'
      }
    },


    resetQR() {
      this.resetForm();
      this.qr_code = null;

      if(this.portals && this.portals.length > 0) {
        this.form.portal = this.portals[0].uuid;
      }
      
      this.updateURL();
      this.setTitle();
      this.getRecords();
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.form));
    },

    setTitle() {
      document.title = this.$route.meta.title + 's | eCourtDate.com';
    },

    postForm() {

      if(!this.form.content) {
        this.$eventBus.$emit('show-alert', {
          text: 'Content is required',
          type: 'danger'
        });
        return;
      }

      if(!this.form.format) {
        this.form.format = 'pdf';
      }

      if(!this.form.language) {
        this.form.language = 'en';
      }

      this.qr_code = null;
      this.errors = null;
      this.processing = true;
      
      this.updateURL();

      this.$http.post('/'+this.$route.meta.base_url, this.form)
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              this.$eventBus.$emit('show-alert', {
                text: this.$route.meta.title + ' generated'
              });

              this.qr_code = response.data;

            }
          })
    },

    updateParameters() {

      var url = this.form.content;

      if(url.indexOf('?') !== -1) {
        url = url.substring(0, url.indexOf('?'));
      }

      var queries = {};

      for(var key in this.parameters) {
        if(this.parameters[key]) {
          queries[key] = this.parameters[key];
        }
      }

      this.form.content = this.buildQueries(url, queries);

      this.postForm();
    },

    downloadFile() {
      this.downloading = true;

      const byteCharacters = atob(this.qr_code);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      var mimeType = 'image/svg+xml';

      if(this.form.format == 'png') {
        mimeType = 'image/png';
      }

      const blob = new Blob(byteArrays, {type: mimeType});

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;

      var title = this.$options.filters.slugString(this.form.content);

      title = title.replace(/-/g, ' ');

      a.download = 'qr-code-'+title+'.'+this.form.format;

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      this.downloading = false;
    },

    generatePDF(data) {

      const blobUrl = window.URL.createObjectURL(data);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = blobUrl;

      a.download = 'downloaded.pdf';

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      window.URL.revokeObjectURL(blobUrl);
    },

    getTotal() {
      this.total = 0;
      this.$http.get('/'+this.$route.meta.base_url+'/total')
          .then(response => {
            if(response && response.status === 200) {
              this.total = response.data;
            }
          })
    },

    getRecords() {
      this.getTotal();

      this.records = [];
      this.$http.get('/'+this.$route.meta.base_url)
          .then(response => {
            if(response && response.data) {
              this.records = response.data;
            }
          })
    },

    getSamples() {
      this.$axios.get('https://assets.ecourtdate.com/demo/qr_codes.json')
          .then(response => {
            if(response && response.data) {
              this.samples = response.data;
            }
          })
    },

    generateQR(record) {
      this.form = record;
      this.postForm();
    },

    deleteRecord(record) {
      this.processing = true;
      this.$http.delete('/qrs/'+record.uuid)
          .then(response => {
            if(response && response.status == 204) {
              this.processing = false;
              this.records = this.records.filter(item => item.uuid !== record.uuid);
            }
          })
    },

    importSample(sample) {

      this.form.title = sample.title;

      this.form.description = sample.description;

      if(sample.amount) {
        this.form.amount = sample.amount;
      }

      if(sample.language) {
        this.form.language = sample.language;
      }

      if(sample.format) {
        this.form.format = sample.format;
      }

      if(sample.action == 'link' && this.currentPortalLink) {
        this.form.content = this.currentPortalLink;
      }

      if(sample.action == 'checkin' && this.currentPortalLink) {
        this.form.content = this.currentPortalLink+'/checkin';
      }

      if(sample.action == 'enroll' && this.currentPortalLink) {
        this.form.content = this.currentPortalLink+'/enroll';
      }

      if(this.currentPortal && this.currentPortal.logo) {
        this.form.logo = this.currentPortal.logo;
      }

      if(sample.action == 'file' && this.form.file_url) {
        this.form.content = this.currentPortalLink+'/fi/'+this.form.file_url;
      }

      if(sample.action == 'esign' && this.form.file_url) {
        this.form.content = this.currentPortalLink+'/fi/'+this.form.file_url;
      }

      if(sample.action == 'call') {
        this.form.title = 'Call '+this.settings.name;
        this.form.content = 'tel:'+this.form.phone;
      }

      if(sample.action == 'contact') {
        this.form.title = 'Save ' + this.form.vcard_name + ' to Contacts';
        this.form.content = 'BEGIN:VCARD\nVERSION:3.0\nN:'+this.form.vcard_name+';;;\nFN:'+this.form.vcard_name+'\nTEL;TYPE=CELL:'+this.form.vcard_phone+'\nEMAIL:'+this.form.vcard_email+'\nEND:VCARD';
      }

      if(sample.action == 'wifi') {
        this.form.title = 'Connect to '+this.form.wifi_ssid;
        this.form.content = 'WIFI:S:'+this.form.wifi_ssid+';T:'+this.form.wifi_security+';P:'+this.form.wifi_password+';;';
      }

      this.postForm();
    },

    
    getPortals() {
      this.$http.get('/portals?sort=default&sortDirection=desc')
          .then(response => {
            if(response && response.data) {
              this.portals = response.data;

              this.portals = this.portals.map(portal => {

                if(portal.logo) {
                  portal.logo = this.$root.portal_assets+portal.logo;
                }

                return portal;
              });

              if(this.portals && this.portals.length > 0 && !this.form.portal) {
                this.form.portal = this.portals[0].uuid;
              }

              if(this.currentPortal && this.currentPortal.logo) {
                this.form.logo = this.currentPortal.logo;
              }

              if(!this.form.content && this.currentPortal) {
                this.form.content = 'https://'+this.currentPortal.link+'.'+this.currentPortal.domain;
              }
            }
          })
    },


    getFiles() {
      this.$http.get('/files?slim=true&fields=name,uuid,url&sort=created_at&sortDirection=desc')
          .then(response => {
            if(response && response.data) {
              this.files = response.data;
            }
          })
    }

  },
}
</script>